import type { App } from 'vue';

import { createVfm } from 'vue-final-modal';

import Echo from 'laravel-echo';
import pusherJs from 'pusher-js';
import services from 'shared/services';
import i18n from '@/app/plugins/i18n';
import 'vue-final-modal/style.css';
import { setupCalendar } from 'v-calendar';
import 'v-calendar/style.css';

window.Pusher = pusherJs;
const vfm = createVfm();

export function setupPlugins(app: App) {
  // Импортируем и инициализируем плагины с папки plugins
  app.use(i18n);
  app.use(vfm);
  app.use(setupCalendar, {});
  // app.use(VCalendar, {
  //   unstyled: true,
  //   locale: {
  //     dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  //     monthNames: [
  //       'Январь',
  //       'Февраль',
  //       'Март',
  //       'Апрель',
  //       'Май',
  //       'Июнь',
  //       'Июль',
  //       'Август',
  //       'Сентябрь',
  //       'Октябрь',
  //       'Ноябрь',
  //       'Декабрь',
  //     ],
  //     monthNamesShort: [
  //       'Янв',
  //       'Фев',
  //       'Мар',
  //       'Апр',
  //       'Май',
  //       'Июн',
  //       'Июл',
  //       'Авг',
  //       'Сен',
  //       'Окт',
  //       'Ноя',
  //       'Дек',
  //     ],
  //   },
  // });
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
    withCredentials: true,
    // encrypted: true,
    authEndpoint: '/api/broadcasting/auth',
    auth: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    },
    authorizer: (channel: any, options: any) => {
      return {
        authorize: (socketId: any, callback: any) => {
          services.common.http.post('/api/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
            .then((response: any) => {
              callback(false, response);
            })
            .catch((error: any) => {
              callback(true, error);
            });
        },
      };
    },
  });
}

declare global {
  interface Window {
    Pusher: any
    Echo: any
    MathJax: {
      typeset: () => void
    }
    ym: any
  }
}
