<template>
  <Component
    :is="tag"
    class="btn"
    :class="`btn--${variant} btn--${size} ${selected ? `btn--${variant}--selected` : ''}` "
    :disabled="disabled"
  >
    <slot name="icon-left" />
    <slot />
    <slot name="icon-right" />
  </Component>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'outline';
type ButtonSize = 'big' | 'large' | 'normal' | 'medium' | 'small' | 'extra-small' | 'full';

defineProps({
  variant: {
    type: String as PropType<ButtonVariant>,
    default: 'primary',
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: 'big',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

const atrrs = useAttrs();

const tag = atrrs.to ? 'RouterLink' : 'button';
</script>

<style lang="scss" scoped>
.btn {
  @apply transition-ease-linear transition justify-center w-full text-center inline-block;
  &--extra-small {
    @apply rounded-xs py-1 px-4;
  }
  &--small {
    @apply rounded-xs py-2 px-6;
  }

  &--medium {
    @apply rounded-xs py-3 px-6;
  }

  &--normal {
    @apply rounded-2xs py-3 px-6;
  }
  &--large {
    @apply rounded-2xs py-4 px-6 text-14;
  }

  &--big {
    @apply rounded-2xs py-4 lg:py-6 px-6 lg:px-3xl;
  }

  &--full {
    @apply px-4 w-full;
  }

  &--primary {
    background-color: var(--color-button-background-primarydefault);
    color: var(--color-button-text-primarydefault);
    border: 1px solid var(--color-button-storke-secondarydefault);

    &:hover {
      background-color: var(--color-button-background-primaryhover);
    }

    &:active {
      background-color: var(--color-button-background-primaryactive);
    }

    &:disabled {
      background-color: var(--color-button-background-primarydisabled);
      color: var(--color-button-text-primarydisabled);
      border-color: var(--color-button-background-primarydisabled);
    }
  }

  // Кнопка вторичного типа
  &--secondary {
    background-color: transparent;
    color: var(--color-button-text-secondarydefault);
    border: 1px solid var(--color-button-storke-secondarydefault);

    &:hover:not(&--selected) {
      background-color: var(--color-button-background-secondaryhover);
    }

    &:active, &--selected {
      background-color: var(--color-button-background-secondaryactive);
      border-color: var(--color-button-background-secondaryactive);
    }

    &:disabled {
      background-color: var(--color-button-background-secondarydisabled);
      color: var(--color-button-text-secondarydisabled);
      border-color: var(--color-button-background-secondarydisabled);
    }
  }

  // Кнопка третичного типа
  &--tertiary {
    background-color: transparent;
    color: var(--color-button-text-tertiarydefault);
    border: 1px solid var(--color-button-storke-tertiarydefault);

    &:hover {
      background-color: var(--color-button-background-tertiaryhover);
    }

    &:active {
      background-color: var(--color-button-background-tertiaryactive);
    }

    &:disabled {
      background-color: var(--color-button-background-tertiarydisabled);
      color: var(--color-button-text-tertiarydisabled);
      border-color: var(--color-button-storke-tertiarydisabled);
    }
  }

  &--outline {
    @apply bg-transparent border-black border-opacity-8 text-black disabled:(text-gray-dark);
  }
}
</style>
