export default {
  create_battle: 'Создать БОЙ!',
  duel: 'Дуэль',
  rating: 'Рейтинг',
  assignments: 'Задания',
  assignments_day: 'Задания дня',
  games_history: 'История игр',
  mine_statistic: 'Моя статистика',
  current_league: 'Текущая лига',
  rating_points: 'Очки рейтинга',
  inventory: 'Инвентарь',
  profile: 'Профиль',
  type_of_game: 'Тип игры',
  training: 'Тренировка',
  wins: 'Победы',
  count_of_games: 'Количество игр',
  watch: 'Смотреть',
  math_battle: 'Математический бой',
  main: 'Главная',
  students: 'Ученики',
  stats: 'Статистика',
};
