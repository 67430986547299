export default {
  status_invited: {
    not_sent: 'Не приглашён',
    wait: 'Ожидание',
    accepted: 'В комнате',
    not_accepted: 'Отклонил',
    left: 'Покинул',
  },
  invite_two_more: 'Чтобы начать турнир, нужно добавить более 2х учеников',
  add_student_to_class: 'Добавить ученика в класс',
  adding_student: 'Добавление в класс',
  search_failed: 'Ученик не найден, этот поиск работает по ИИН, Фамилии и имени',
  select_class: 'Выберите класс',
  active_class: 'Активный класс',
  settings: 'Настройки турнира',
  create: 'Создать турнир',
};
