import { useLocalStorage } from '@vueuse/core';
import { ERoles } from 'modules/auth/auth.dto';

const role_session = useLocalStorage<ERoles>('role_session', ERoles.STUDENT);

export function useRoleService() {
  const getRole = computed(() => {
    return role_session.value ?? ERoles.STUDENT;
  });
  const setRole = (role: ERoles) => {
    role_session.value = role;
  };

  return {
    getRole,
    setRole,
  };
}
