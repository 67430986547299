import type { RouteRecordRaw, Router } from 'vue-router';
import auth from '@/app/middleware/auth';
import isTeacher from '@/app/middleware/isTeacher';

const moduleRoute: RouteRecordRaw = {
  path: '/students',
  name: 'Students',
  component: () => import('@/shared/layouts/TheLayoutDefault.vue'),
  meta: {
    middleware: [auth, isTeacher],
  },
  children: [
    {
      path: '',
      name: 'Students.Main',
      meta: {
        title: 'Students',
      },
      component: () => import('@/modules/students/pages/Main.vue'),
    },

  ],
};

export default (router: Router) => {
  router.addRoute(moduleRoute);
};
