import { createApp } from 'vue';
import App from '@/app/App.vue';
import { setupStore } from '@/shared/stores';
import { router, setupRouter } from '@/app/router';
import { registerGlobalComponents } from '@/app/providers/setup-global-components';
import { registerModules } from '@/app/providers/register-modules';
import { setupPlugins } from '@/app/providers/setup-plugins';
import '@unocss/reset/tailwind.css';
import 'virtual:uno.css';
import '@/app/styles/style.scss';
import { setupRouterGuards } from '@/app/middleware/beforeEachGuard';
import { initMetrika } from '@/app/plugins/yandex-metrika';
import { initSentry } from '@/app/plugins/sentry';

export async function init() {
  const app = createApp(App);
  initMetrika();
  initSentry(app);
  setupStore(app);
  setupPlugins(app);
  registerModules(router);
  setupRouter(app);
  setupRouterGuards(router);
  registerGlobalComponents(app);
  app.mount('#app');
}
