<template>
  <div>
    <label
      class="flex items-center cursor-pointer gap-3 text-text-primary"
      :class="[wrapperClass]"
      :for="id"
    >
      <input
        v-bind="$attrs"
        :id="id"
        :checked="checked"
        class="checkbox__input"
        type="checkbox"
        :value="checked"
        @change="onChange"
      >
      <span class="checkbox" :class="{ 'checkbox--checked': checked }" />
      <slot />
    </label>
    <p
      class="transition-all transition-ease duration-300 text-field-text-error text-[14px]"
      :class="!isError ? 'h-0 opacity-0 p-0' : 'h-auto opacity-100 pt-3'"
    >
      <!-- @slot slot for input error message -->
      <slot name="error" />
    </p>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  /**
   * ID of the checkbox
   */
  id: {
    type: String,
    required: true,
  },
  /**
   * Value of the checkbox
   * @model
   */
  checked: {
    type: Boolean,
    default: false,
  },
  wrapperClass: {
    type: String,
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:checked']);

function onChange(e: Event) {
  emit('update:checked', (e.target as HTMLInputElement).checked);
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.checkbox {
  @apply block min-w-4 min-h-4 w-4 h-4 border-1 border-solid rounded-tiny border-stroke-secondary bg-transparent;

  &--checked {
    @apply border-stroke-primary bg-all-ghost-primary-300;
  }

  &__input {
    @apply h-full w-full hidden;
    &:disabled + .checkbox {
      @apply bg-gray-100;
    }
  }
}
</style>
