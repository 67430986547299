import type { ILeague } from 'modules/rating/rating.dto';

export interface ILogin {
  iin: string
  password: string
}
export interface ILoginResponse {
  token: string
  name: string
  role: ERoles
}
export interface IRegister {
  name: string
  surname: string
  email: string
  iin: number
  password: string
  password_confirmation: string
}

export interface IUserCharacter {
  id: number
  name: string
  style: string
  svg: string
  image: string
  created_at: string
  updated_at: string
}

export interface IUserSchool {
  id: number
  name: {
    ru: string
    kk: string
  }
  settlement_id: number
  created_at: string
  updated_at: string
}
export interface IUserClassRoom extends IUserSchool {}

export const enum ERoles {
  STUDENT = 'student',
  TEACHER = 'teacher',
  ADMIN = 'admin',

}

export interface IAvatar {
  id: number
  image: string
}
export interface IUser {
  id: number
  name: string
  surname: string
  email: string
  iin: string
  school_id: number | null
  school: IUserSchool | null
  class_room_id: number | null
  class_room: IUserClassRoom | null
  character_id: number | null
  email_verified_at: null
  character: IUserCharacter | null
  role: ERoles
  avatar: IAvatar
  created_at: string
  updated_at: string
}

export interface IUserStats {
  count_battles: number
  count_battles_wins: number
  answers_count: number
  place: number
  exp: number
  league: ILeague
}

export interface IUserBattleUser {
  id: number
  name: string
  surname: null | string
  rounds: number
  score: number

}
export interface IUserBattle {
  id: number
  tournament_id: number
  winner_id: null | number
  status: 'completed' // ??
  users: IUserBattleUser[]
  created_at: string
  updated_at: string
  time_start: string
}
